import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
@Injectable()
export class HomeService {
  constructor(private http: HttpClient) { }

  sendotp(mobile: any, email) {
    const formData: FormData = new FormData();
    if (mobile) {
      formData.append("phoneno_or_email", mobile);
    }
    if (email) {
      formData.append("phoneno_or_email", email);
    }
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/send-otp`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  verifyotp(mobile: any, email, otp) {
    if (mobile) {
      var formData = {
        phoneno_or_email: mobile,
        otp: otp,
      };
    }
    if (email) {
      var formData = {
        phoneno_or_email: email,
        otp: otp,
      };
    }
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/verify-otp`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  register(id: any) {
    var formData = {
      enrollment_number: id,
    };
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/fetch-user`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  saveDetails(
    id,
    name,
    email,
    mobile,
    institution,
    course,
    degree,
    month,
    year,
    date,
    address,
    city,
    country,
    pincode,
    date_of_birth,
    gender,
    adhaar_no,
    digi_locker_id,
    photo: File,
    instituteId,
    courseId,
    degreeId
  ) {

    const formData: FormData = new FormData();
    if (photo) {
      formData.append('profile_photo', photo, photo.name);
    }
    formData.append('registration_no', id);
    formData.append('full_name', name);
    formData.append('email', email);
    formData.append('mobile', mobile);
    formData.append('institution', institution);
    formData.append('course', course,);
    formData.append('degree', degree,);
    formData.append('month_of_pass', month);
    formData.append('internship_c_date', date);
    formData.append('address', address);
    formData.append('country', country);
    formData.append('pincode', pincode);
    formData.append('city', city);
    formData.append('year_of_pass', year);
    formData.append('date_of_birth', date_of_birth);
    formData.append('gender', gender);
    formData.append('adhaar_no', adhaar_no);
    formData.append('digilocker_id', digi_locker_id);
    //   formData.append('profile_photo', photo);
    formData.append('institution_id', instituteId);
    formData.append('degree_id', degreeId);
    formData.append('course_id', courseId);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getEventList(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/booking/hold-event-list?page=1`).pipe(map(user => user));
  }

  getaccompanyList(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/accoumpany-list`).pipe(map(user => user));
  }

  searchseating(search): Observable<any> {
    let auth_token = "EMlrl6jEc20xg0qwkn5NCPGdmFl8c5M9Wc4ovn4WxMxgDVmkFNJzQ8lpkt0IHTFpxNrRutXzXjlGnP9kdd9n1E8CMFi8YuLODmkH";
    let accesskey = "4662dd3f-af0f-4ab4-bf39-9a4a9b3330ba";
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': auth_token,
      'accesskey': accesskey
    });

    const requestOptions = { headers: headers };
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/student-seat-info?registration_no=` + search, requestOptions).pipe(map(user => user));
  }


  home(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/home`).pipe(map(user => user));
  }

  getuserdetails(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/get-user`).pipe(map(user => user));
  }

  feedbacksingle(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/booking/get-feedback`).pipe(map(user => user));
  }

  feedbackquestion(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/booking/get-questions`).pipe(map(user => user));
  }

  details(id, type): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/booking/details/` + id + '?type=' + type).pipe(map(user => user));
  }


  holdseat(eventid, slotid, userid) {

    var formData = {
      event_id: eventid,
      event_slot_id: slotid,
      user_id: userid
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/hold-seat`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  booking(eventid, slotid, userid, accompany) {

    var formData = {
      event_id: eventid,
      event_slot_id: slotid,
      user_id: userid,
      tot_acompany: accompany
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/booking/create`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  postaladdress(name, email, mobile, state, address, postaladdress, zipcode, country) {
    var formData = {
      full_name: name,
      email: email,
      mobile: mobile,
      state: state,
      address: address,
      postal_address: postaladdress,
      zipcode: zipcode,
      country: country,


    };
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/postal-user/create`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }



  getQuestion(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/question-list`).pipe(map(user => user));
  }


  feedback(userid,
    eventid, 
    slotid, 
    bookingid,
    question, 
  ) {

    var formData = {
      user_id:userid,
      event_id: eventid,
      event_slot_id: slotid,
      bookingid:bookingid,
      feedback:JSON.stringify(question), 
    
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/booking/submit-feedback`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }


}
