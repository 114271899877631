import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class dcnyService {
    constructor(private http: HttpClient) { }

    // home
    
    // getHomeImages() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getdoc`).pipe(map(user => user));
    // }
    
    // getEventsAndNewsDetails() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getevent`).pipe(map(user => user));
    // }

    // getUpcomingEventsDetails() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getupcommingevents`).pipe(map(user => user));
    // }


    // getHouseOfTheLordDetails() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getmedia`).pipe(map(user => user));
    // }
    
    // getPlaylistDetails() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getplaylist`).pipe(map(user => user));
    // }

    // getLatestCountdownDetails1() {
        
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/latestcountdown`).pipe(map(user => user));
        
    // }

    // storeLatestCountdownDetails() {
        
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/latestcountdown`).pipe(map(user => user));
        
    // }

    // getTestimonialsDetails() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/gettestimonials`).pipe(map(user => user));
    // }


    // getWelcomeToChurchDetails() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getpastor`).pipe(map(user => user));
    // }


    // //contactus

    // addContactUs(name: any, email: any, phone: any, prayer_request: any, testimonials: any) {
    //     const formData: FormData = new FormData();
    //     formData.append('name', name);
    //     formData.append('email', email);
    //     formData.append('mobile_no', phone);
    //     formData.append('prayer_request', prayer_request);
    //     formData.append('testimonials', testimonials);
    //     console.log(formData)
    //     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/contact`, formData).pipe(map(user => {
    //         return user;
    //     }));
    // }

    // //upcoming event

    // getUpcomingSingleEventsDetails(date:any) {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/searchdatetime?date=`+date).pipe(map(user => user));
    // }
    

    // paypalData(
    //     name: string,
    //     email: string,
    //     amount: string,
    //     payment_method: string,
    //     payment_for: string,
    //     paymentId: string,
    //     orderId: string
    //   ) {
    //     const formData: FormData = new FormData();
       
    
    //     formData.append("name", name);
    //     formData.append("email", email);
    //     formData.append("amount", amount);
    //     formData.append("payment_method", payment_method);
    //     formData.append("payment_for", payment_for);
    //     formData.append("paymentId", paymentId);
    //     formData.append("orderId", orderId);
    //     console.log(formData);
    //     return this.http
    //       .post<any>(
    //         `${environment.apiUrl}${environment.apiPrefix}/users/payment`,
    //         formData
    //       )
    //       .pipe(
    //         map((user) => {
    //           return user;
    //         })
    //       );
    //   } 

    // //   give
    
    // getpayment() {
    //     return this.http
    //       .get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getpayment`)
    //       .pipe(
    //         map((user) => {
    //           return user;
    //         })
    //       );
    //   }
}
