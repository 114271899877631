import { Component, Renderer2, isDevMode } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { environment } from '../environments/environment';
import { HostListener } from '@angular/core';
import { dcnyService } from './_services';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  previousUrl: string;
  loadAPI: Promise<any>;
  curYear: string = (new Date()).getFullYear().toString();

  constructor(private renderer: Renderer2, private router: Router) {
    if (this.curYear != '2019') {
      this.curYear = '2019 - ' + this.curYear;

    }

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1);

          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
          }






          this.previousUrl = currentUrlSlug;


        }


      });
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });

  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }


    if (!isFound) {





      var dynamicScripts = [
        'assets/js/bootstrap.bundle.min.js',
        'assets/js/jquery.fancybox.min.js',
        'assets/js/jquery.slim.min',
        'assets/js/popper.min'  ,     
        'assets/js/menu.js',
        'assets/js/slick.min.js',
  
      
    

      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    }
  }
}