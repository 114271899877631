import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
// canActivate: [AuthSuperadminGuard],
const routes: Routes = [
  // { path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule) },
  // { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'instructions', loadChildren: () => import('./instructions/instructions.module').then(m => m.InstructionsModule) },
   { path: 'feedback-form', loadChildren: () => import('./feedback/feedback.module').then(m => m.feedbackModule),canActivate: [AuthSuperadminGuard] },
 { path: 'chief-guests', loadChildren: () => import('./chief-guests/chief-guests.module').then(m => m.ChiefGuestsModule) },
  //  { path: 'registrations', loadChildren: () => import('./registrations/registrations.module').then(m => m.RegistrationsModule) },
   { path: 'feedback', loadChildren: () => import('./registrations/registrations.module').then(m => m.RegistrationsModule) },
   { path: 'verify-otp', loadChildren: () => import('./verify-otp/verify-otp.module').then(m => m.VerifyOtpModule) },
   { path: 'registration1', loadChildren: () => import('./registartion1/registartion1.module').then(m => m.Registartion1Module) },
   { path: 'details', loadChildren: () => import('./details/details.module').then(m => m.DetailsModule) },
   { path: 'digi-lockers-details', loadChildren: () => import('./digi-lockers-details/digi-lockers-details.module').then(m => m.DigiLockersDetailsModule) },
   { path: 'mode-of-reservation', loadChildren: () => import('./mode-of-reservation/mode-of-reservation.module').then(m => m.ModeOfReservationModule) },
   { path: 'upcoming-convocation', loadChildren: () => import('./upcoming-convocation/upcoming-convocation.module').then(m => m.UpcomingConvocationModule) },
   { path: 'select-accompany', loadChildren: () => import('./select-accompany/select-accompany.module').then(m => m.SelectAccompanyModule) },
   { path: 'confirmation', loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule) },
   { path: 'speed-post', loadChildren: () => import('./speed-post/speed-post.module').then(m => m.SpeedPostModule) },
   { path: 'aboutus', loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule) },
   { path: 'help-support', loadChildren: () => import('./help-support/help-support.module').then(m => m.HelpSupportModule) },
   { path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)},
   { path: 'terms-condition', loadChildren: () => import('./terms-condition/terms-condition.module').then(m => m.TermsConditionModule)},
   { path: 'refund-policy', loadChildren: () => import('./refund-policy/refund-policy.module').then(m => m.RefundPolicyModule)},
   { path: 'success', loadChildren: () => import('./success/success.module').then(m => m.SuccessModule)},
   { path: 'failure', loadChildren: () => import('./failure/failure.module').then(m => m.FailureModule)},
   { path: 'speed-post-confirmation', loadChildren: () => import('./speed-post-confirmation/speed-post-confirmation.module').then(m => m.SpeedPostConfirmationModule)},
   { path: 'upload-photo', loadChildren: () => import('./upload-photo/upload-photo.module').then(m => m.UploadPhotoModule)},
   { path: 'info', loadChildren: () => import('./info/info.module').then(m => m.InfoModule)},
   { path: 'delete-photo', loadChildren: () => import('./delete-phot/delete-phot.module').then(m => m.DeletePhotModule)},
   { path: 'cancelled', loadChildren: () => import('./failure/failure.module').then(m => m.FailureModule)},
   { path: 'employee-confirmation', loadChildren: () => import('./employee-confirmation/employee-confirmation.module').then(m => m.EmployeeConfirmationModule)},
   { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule)},

   // { path: 'success', loadChildren: () => import('./success/success.module').then(m => m.SuccessModule) },
  // { path: 'failure', loadChildren: () => import('./failure/failure.module').then(m => m.failureModule) },
  // { path: 'terms-condition', loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsServiceModule) },
  // { path: 'rules-and-regulation', loadChildren: () => import('./rules-and-regulation/rules-and-regulation.module').then(m => m.RulesAndRegulationModule) },
  // { path: 'contactus', loadChildren: () => import('./contactus/contactus.module').then(m => m.ContactUsComponentModule) },
  


  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
