import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
    register(name: string, email: string, phone_number: string, gender: string, dob: string, address: string,employee_code:any,institution_name:any) {
        const formData =
        {
            "loginType": 3,
            "name": name,
            "email": email,
            "phone_number": phone_number,
            "gender": gender,
            "dob": dob,
            "address": address,
            "enrollment_Number":employee_code,
            "institution":institution_name
        }
        console.log(formData)
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/register`, formData).pipe(map(user => {
            console.log("user", user);

            // if (user.status.code == 0) {
            //     localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
            //     localStorage.setItem(`${environment.appName}` + '_adminName', user.data.name);
            //     localStorage.setItem(`${environment.appName}` + '_adminId', user.data.reg_ID);
            //     localStorage.setItem(`${environment.appName}` + '_role', user.data.roleId);
            //     localStorage.setItem(`${environment.appName}` + '_status', user.data.status);
            //     localStorage.setItem(`${environment.appName}` + '_domain', '');
            // }

            return user;
        }));
    }

    localLoginWithEmail(type: any, name: string, email: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/register`, { loginType: type, name: name, email: email }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.data.user));
                // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
                // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');



            }
            console.log(user);

            return user;
        }));

    }


    localLoginWithMobileNumber(type: any, name: string, phone_number: string) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/register`, { loginType: type, name: name, phone_number: phone_number }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.data.user));
                // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
                // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');



            }
            console.log(user);

            return user;
        }));

    }


    staffLoginWithEmail(type: any, roll_no: string, email: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/register`, { loginType: type, roll_no: roll_no, email: email }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.data.user));
                // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
                // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');



            }
            console.log(user);

            return user;
        }));

    }


    staffLoginWithMobileNumber(type: any, roll_no: string, phone_number: string) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/register`, { loginType: type, rollNumber: roll_no, phone_number: phone_number }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.data.user));
                // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
                // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');



            }
            console.log(user);

            return user;
        }));

    }

    studentLoginWithEmail(type: any, roll_no: string, email: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/register`, { loginType: type, roll_no: roll_no, email: email }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.data.user));
                // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
                // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');



            }
            console.log(user);

            return user;
        }));

    }


    studentLoginWithMobileNumber(type: any, roll_no: string, phone_number: string) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/register`, { loginType: type, rollNumber: roll_no, phone_number: phone_number }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.data.user));
                // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
                // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');



            }
            console.log(user);

            return user;
        }));

    }

    sendOtp(countrycode: any, mobile_no: any) {

        const formData: FormData = new FormData();
        formData.append('country_code', countrycode);
        formData.append('phone_no', mobile_no);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/send-otp`, formData).pipe(map(user => {
            return user;
        }));

    }


    // this.f.name.value,this.f.email.value,this.f.phone_number.value,this.f.gender.value,this.f.dob.value,this.f.address.value
    verifyOtp(name: string, email: string, phone_number: string, gender: string, dob: string, address: string, otp: any,employee_code:any,institution_name:any) {
        const formData =
        {
            "loginType": 3,
            "name": name,
            "email": email,
            "phone_number": phone_number,
            "gender": gender,
            "dob": dob,
            "address": address,
            "otp": otp,
            "enrollment_Number":employee_code,
            "institution":institution_name
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/verify`, formData).pipe(map(user => {
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.data.user));
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
            }
            return user;
        }));

    }

    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        // localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_userid');


        // localStorage.removeItem(`${environment.appName}` + '_adminName');
        // localStorage.removeItem(`${environment.appName}` + '_adminId');
        // localStorage.removeItem(`${environment.appName}` + '_status');
        // localStorage.removeItem(`${environment.appName}` + '_domain');
        // localStorage.removeItem(`${environment.appName}` + '_user_room_details');
        // localStorage.removeItem(`${environment.appName}` + '_userId');


    }

    getUser() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-userSingle`).pipe(map(user => user));
    }

}
